import logo from './logo.svg';
import './App.css';
import SignIn from './Components/SignIn';
import { useEffect, useState } from 'react';
import DropFile from './Components/DropFile';

function App() {
  const [token, setToken] = useState();

  return (
    <div className="App">
      {!token ?
        <SignIn setToken={setToken} />
        :
        <DropFile token={token} />
      }
    </div>
  );
}

export default App;
