import React from "react";

export default (props) => {
  const { show, setShow, title, description, color, textColor, setDesc } =
    props;
  if (!show) {
    return <></>;
  }
  return (
    <div
      className="position-absolute top-0 start-0 vw-100 vh-100 bg-black bg-opacity-50 d-flex flex-row justify-content-center align-items-center"
      onClick={() => {
        if (setDesc) setDesc("");
        setShow(false);
      }}
    >
      <div
        className={
          color ? "container p-3 bg-" + color : "container p-3 bg-white"
        }
        onClick = {e => e.stopPropagation()} 
      >
        <div
          className={
            title
              ? "d-flex w-100 flex-row justify-content-between align-items-center ms-3 me-3"
              : "me-2 w-100 d-flex flex-row justify-content-end align-items-start"
          }
        >
          <p
            className={
              typeof textColor === "string"
                ? "h3 text-" + textColor
                : "h3 text-black"
            }
          >
            {title}
          </p>
          <button onClick={() => setShow(false)} className="btn-close me-3"></button>
        </div>
        <hr />
        <div
          className={
            typeof textColor === "string"
              ? "m-3 text-" + textColor
              : "m-3 text-black"
          }
        >
          {description}
        </div>
      </div>
    </div>
  );
};
