import React, { useState, useContext } from "react";
import { useEffect } from "react";
import PasswordContext from "./Context/PasswordContext";

export default (props) => {
  const { password_confirmation } = props;
    const error = useContext(PasswordContext)["passErr"]

  const [confirmPass, setConfirmPass] = useState();
  const [password, setNewPass] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    password_confirmation(password, confirmPass);
  };

  return (
    <div className="container">
      <form onSubmit={(e) => handleSubmit(e)} className="form">
        {error !== "" && (
            <p className="h4 text-danger">
                {error}
            </p>
        )}
        <div className="form-floating mt-3 mb-3">
          <input
            type="password"
            onChange={(e) => setNewPass(e.currentTarget.value)}
            placeholder="New Password"
            className="form-control"
          />
          <label htmlFor="">New Password</label>
        </div>
        <div className="form-floating mb-3 mt-3">
          <input
            type="password"
            onChange={(e) => setConfirmPass(e.currentTarget.value)}
            className="form-control"
            placeholder="Confirm Password"
          />
          <label htmlFor="">Confirm Password</label>
        </div>
        <p className="h6 text-muted my-2">
          Password's must be at least 8 characters, contain at least 1 number,
          special character, an uppercase, and lowercase
        </p>
        <div className="d-grid">
          <input
            type="submit"
            className="btn btn-outline-dark"
            value="Set Password"
          />
        </div>
      </form>
    </div>
  );
};
