import { AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import React, { useEffect, useState } from "react";
import UserPool from "../UserPool";
import Modal from "./Modal";
import NewPassword from "./NewPassword";
import PasswordContext from "./Context/PasswordContext";

export default (props) => {
  const { setToken } = props;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [passErr, setPassErr] = useState("");

  const [cognitoUser, setUser] = useState();
  const [title, setTitle] = useState("Error");

  const password_confirmation = (newPass, confirmPass) => {
    if (newPass !== confirmPass) {
      setError("Passwords must be the same");
      setShow(true);
      setTitle("Error");
    }

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (e) => {
        let token = e.getAccessToken();
        console.log(token["getJwtToken"]);
        setToken(e.getAccessToken().getJwtToken());
      },
      onFailure: (e) => {
        if (e.message) {
          setShow(true);
          setTitle("Error");
          setError(
            <div className="d-flex flex-row align-items-center">
              <p className="fs-3 col">{e.message}</p>
              <div className="vr"></div>
              <p className="fs-5 col-2 m-3 ms-5">
                Unexpected error please contact{" "}
                <a href="mailto:support@ascendingdc.com">
                  support@ascendingdc.com
                </a>
              </p>
            </div>
          );
        }
        console.log(e);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        user.completeNewPasswordChallenge(newPass, userAttributes, {
          onSuccess: (e) => {
            let token = e.getAccessToken().getJwtToken();
            console.log(token);
            setToken(token);
          },
          onFailure: (e) => {
            setPassErr(e.message);
            console.log(e.message);
          },
        });
      },
    });
  };

  const newPass = () => {
    setTitle("Reset Password")

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: function(result) {
          console.log('call result: ' + result);
      },
      onFailure: function(err) {
          alert(err);
      },
      inputVerificationCode() {
          var verificationCode = prompt('Please input verification code ', '');
          var newPassword = prompt('Enter new password ', '');
          cognitoUser.confirmPassword(verificationCode, newPassword, this);
      }
  });

  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    setUser(user);

    let authentication = user.authenticateUser(authDetails, {
      onSuccess: (e) => {
        let token = e.getAccessToken();
        console.log(token["getJwtToken"]);
        setToken(e.getAccessToken().getJwtToken());
      },
      onFailure: (e) => {
        if (e.message) {
          setShow(true);
          setTitle("Error");
          setError(
            <div className="row align-items-center">
              <p className="fs-2 col text-start">{e.message}</p>
              <a href="#" onClick={() => newPass()} className="btn-outline-dark btn col-3">Forgot Password?</a>
            </div>
          );
        }
        console.log(e);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified;
        delete userAttributes.email;
        setShow(true);
        setTitle("Create a new Password");
        setError(
          <NewPassword
            password_confirmation={password_confirmation}
            error={passErr}
          />
        );
      },
    });
  };

  return (
    <PasswordContext.Provider value={{
      passErr
    }}>
      <div className="container">
        <form className="form m-5 pt-5" onSubmit={handleSubmit}>
          <p className="h1 mb-3">CAHSS Provincial Data Provider Login</p>
          <div className="form-floating mt-3 mb-3">
            <input
              type="email"
              className=" form-control"
              placeholder="Email"
              onChange={(e) => setEmail(e.currentTarget.value)}
            />
            <label htmlFor="username">Email</label>
          </div>
          <div className="form-floating mt-3 mb-3">
            <input
              type="password"
              onChange={(e) => setPassword(e.currentTarget.value)}
              className="form-control"
              placeholder="Password"
            />
            <label htmlFor="password">Password</label>
          </div>
          <button className="btn-outline-dark btn fs-3 ps-5 pe-5">Login</button>
        </form>
        <Modal
          setShow={setShow}
          show={show}
          description={error}
          title={title}
        />
      </div>
    </PasswordContext.Provider>
  );
};
