import axios from "axios";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSpring, animated, config, to } from "react-spring";
import Modal from "./Modal";
import moment from "moment";

export default (props) => {
  const { token } = props;

  const [navHeight, setNavheight] = useState();
  const [domHeight, setDomHeight] = useState();
  const [show, setShow] = useState(false);
  const [province, setProvince] = useState("Manitoba");
  const [tableType, seetDatatype] = useState("Condemned");
  const [type, setType] = useState("Abattoir");
  const [date, setDate] = useState(new Date().toISOString().substring(0, 7));
  const [faSize, setFaSize] = useState(true);

  const [confirm, setConfirm] = useState(false);
  const [fileObj, setFileObj] = useState();

  const [desc, setDesc] = useState("File must be a csv file");

  const nav = useRef();
  const dom = useRef();
  const fa = useRef();

  const [options, setOptions] = useState([])

  useEffect(() => {
    if (type === 'Abattoir') {
      setOptions(['Manitoba', 'Saskatchewan', 'Alberta'])
    } else {
      setOptions(['AHC'])
    }
  }, [type])

  useEffect(() => {
    console.log();
  }, []);

  useEffect(() => {
    if (confirm) {
      try {
        setShow(true);
        setDesc(
          <div className="d-flex justify-content-center align-items-center">
            <div className="loader"></div>
          </div>
        );
        handleSubmit(fileObj);
      } catch (error) {
        console.log(error);
      } finally {
        setConfirm(false);
      }
    }
  }, [confirm]);

  const handleSubmit = async (e) => {
    // can change the api url to check the type state on line 16
    let params = {
      province: province,
      type: type,
      name: e[0].name,
      tableType: tableType,
      fileCreationDate: date,
    }

    if (province === 'AHC') {
      params = {
        provider: province,
        type: type,
        name: e[0].name,
        tableType: tableType,
        fileCreationDate: date,
      }
    }

    await axios
      .post(
        process.env.REACT_APP_API_URL + "/web",
        e[0],
        {
          headers: {
            Authorization: token,
          },
          params: params,
        }
      )
      .then((x) => {
        console.log(x);
        setDesc("File Uploaded");
        setShow(true);
      })
      .catch((x) => {
        console.log(x);
        setDesc(
          <div>
            Something happened while uploading. <br />
            If the problem persists please contact{" "}
            <a href="mailto:support@ascendingdc.com">support@ascendingdc.com</a>
          </div>
        );
        setShow(true);
      });
  };

  const onDrop = (e) => {
    if (e.length === 0) {
      setDesc("File must be a csv file");
      setShow(true);
      return;
    }
    setShow(true);
    setDesc(
      <div className="container">
        <div className="row">
          <p className="h6 col">
            <strong>
              Province: <br />
            </strong>
            {province}
          </p>
          <p className="h6 col">
            <strong>
              Data Type: <br />
            </strong>
            {type}
          </p>
          <p className="h6 col">
            <strong>
              File Name: <br />
            </strong>
            {e[0].name}
          </p>
          {province === "Alberta" && (
            <p className="h6 col">
              <strong>
                Table Type: <br />
              </strong>
              {tableType}
            </p>
          )}
        </div>
        <button className="btn-primary btn" onClick={() => setConfirm(true)}>
          Confirm
        </button>
      </div>
    );
    setFileObj(e);
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      let domH = dom.current.clientHeight;
      let navH = nav.current.clientHeight;
      setDomHeight(domH);
      setNavheight(navH);
      if (domH - navH - 200 < 160) {
        setFaSize(false);
      } else {
        setFaSize(true);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "text/csv": [".csv"],
      },
    });

  return (
    <div ref={dom} style={{ height: "100vh" }}>
      <nav className="nav border-bottom p-3" ref={nav}>
        <div className="d-flex flex-row justify-content-between w-100 ms-5 me-5">
          <p className="h3">CAHSS file drop</p>
          <button
            className="btn-danger btn"
            onClick={() => {
              localStorage.clear();
              window.location.reload(false);
            }}
          >
            Logout
          </button>
        </div>
      </nav>
      <div className="container p-0">
        <div className="d-flex flex-row">
          <div className="col">
            <div className="d-grid">
              <input
                type="month"
                onChange={(e) => setDate(e.currentTarget.value)}
                value={date}
                className="mt-3 p-2 border rounded"
              />
            </div>
            <select
              className="form-select m-3 ms-0"
              value={province}
              onChange={(e) => setProvince(e.currentTarget.value)}
            >
              {options.map(d => {
                return (
                    <option value={d} key={d}>{d}</option>
                )
              })}
            </select>
          </div>
          {/* if you want to include the laboratory data type simply delete line 197 */}
          <div className="col">
            <select
              className="form-select m-3 me-0"
              onChange={(e) => {
                const val = e.currentTarget.value
                if (val === 'Laboratory') {
                  setProvince('AHC')
                } else {
                  setProvince('Manitoba')
                }
                setType(val)
              }}
            >
              <option value="Abattoir">Abattoir</option>
              <option value="Laboratory">Laboratory</option>
            </select>
            <select
              disabled={province !== "Alberta"}
              onChange={(e) => seetDatatype(e.currentTarget.value)}
              className="form-select m-3 me-0"
            >
              {province !== "Alberta" && <option value="">Data Type</option>}
              <option value="Condemned">Condemned</option>
              <option value="Inspected">Inspected</option>
            </select>
          </div>
        </div>
      </div>
      <div
        {...getRootProps()}
        className="container border mt-3 justify-content-center align-items-center d-flex"
        style={{ height: domHeight - navHeight - 200 }}
      >
        <div className="input-group justify-content-center align-items-center d-flex">
          <input type="file" {...getInputProps()} />
          {faSize === true && (
            <i
              ref={fa}
              className={
                isDragAccept
                  ? "fa-solid fa-file-arrow-up fa-10x accepted-animation"
                  : isDragReject
                  ? "fa-solid fa-file-arrow-up fa-10x rejected-animation"
                  : "fa-solid fa-file-arrow-up fa-10x"
              }
            ></i>
          )}
        </div>
      </div>
      <Modal
        setShow={setShow}
        show={show}
        description={desc}
        setDesc={setDesc}
        title={"Message"}
      />
    </div>
  );
};
